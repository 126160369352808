import { singleSignOn, veterainary } from '@/axios'

export default {
  login (payload) {
    return singleSignOn().post('sso-login', payload)
  },
  checkUser (paylod) {
    return singleSignOn().post('sso-check', paylod)
  },
  getDoctorInfo () {
    return veterainary().get('doctors')
  },
  getClinicInfo () {
    return veterainary().get('clinics')
  },
  logout () {
    return singleSignOn().post('logout')
  }
}
